import { Spin, Alert } from 'antd';
import { useLocation } from 'react-router-dom';
import { loginAuth } from 'api/authAndCustomer/login.api';
import { useEffect, useState } from 'react';
import { getUserInfo } from 'api/authAndCustomer/login.api';
import { setAuthenticate } from 'redux/actions/auth.action';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UserInfo } from 'api/interface/loginApi';

interface Profile {
  displayName: string;
  email: string;
  google_id: string;
  image: string;
}

const AuthGoogle = () => {
  const [errorMessage, setErrorMessage] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // Parse query string
  const query = new URLSearchParams(location.search);
  const accessToken = query.get('accessToken');

  useEffect(() => {
    const fetchDataAsync = async () => {
      let loginData = {} as any;
      let profileData = {} as Profile;

      try {
        if (!accessToken) throw 'Not valid token';

        const profile = await getUserInfo(accessToken);
        const userData = profile.data as UserInfo;

        const { data, err } = await loginAuth({ access_token: accessToken });
        if (!!err) throw err;

        loginData = data;

        profileData = {
          displayName: userData.name,
          email: userData.email,
          google_id: userData.id,
          image: userData.picture,
        };

        dispatch(
          setAuthenticate({
            ...loginData,
            ...profileData,
            authenticated: true,
            loading: false,
            google: { access_token: accessToken },
          }),
        );
        return history.replace('/');
      } catch (e) {
        setErrorMessage(true);
        setTimeout(() => {
          history.replace('/signOut');
        }, 4000);
      }
    };

    fetchDataAsync();
  }, []);
  return (
    <div
      style={{
        position: 'fixed',
        height: '100%',
        width: '100%',
        top: '0',
        left: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      {errorMessage}
      {errorMessage && (
        <Alert
          message="Error"
          description="We have not been able to obtain your credentials, please log in again"
          type="error"
          showIcon
          style={{
            position: 'absolute',
            top: '15px',
          }}
        />
      )}
      <Spin size="large" />
    </div>
  );
};

export default AuthGoogle;
