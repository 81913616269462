import React from 'react';
import { Menu } from 'antd';
import { useDispatch } from 'react-redux';
import { useGoogleApi } from 'react-gapi';
import logout from 'config/google/logout';
import { scopes } from 'config/google/scopes';
import useStateRedux from 'hooks/useStateRedux';
import { Link } from 'react-router-dom';
import { CheckCircleFilled, SettingOutlined } from '@ant-design/icons';

const menu = (brand?: boolean) => {
  // @ts-ignore
  const gapi = useGoogleApi({ scopes });
  const auth = gapi?.auth2?.getAuthInstance();
  const dispatch = useDispatch();
  const { user_full_name, email }: any = useStateRedux('auth');

  const onSingOut = () => {
    return logout(dispatch, auth);
  };

  return (
    <Menu className="aside__menu-menu">
      <Menu.Item key="0">
        <p>
          <b>{user_full_name}</b>
        </p>
        <p>{email}</p>
      </Menu.Item>
      {!brand && (
        <>
          <Menu.Item key="1">
            <Link to="/setting">
              <SettingOutlined style={{ color: '#72757a' }} />{' '}
              <span>Settings</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/accountProfile">
              <CheckCircleFilled style={{ color: '#72757a' }} />{' '}
              <span>Subscription</span>
            </Link>
          </Menu.Item>
        </>
      )}
      <Menu.Divider />
      <Menu.Item key="3" className="text-center" onClick={onSingOut}>
        <span className="text-danger">Sign out</span>
      </Menu.Item>
    </Menu>
  );
};

export default menu;
