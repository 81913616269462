import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getRefreshToken, headers } from '../utils/refreshToken';

const instance = axios.create({
  baseURL: process.env.REACT_APP_SIGNATURES_API,
});

const refreshToken = (failedRequest: any) => {
  console.log('refresh token');
  return getRefreshToken()
    .then((res) => {
      failedRequest.response.config.headers['Authorization'] = `Bearer ${res}`;
      return Promise.resolve();
    })
    .catch((err) => {
      console.log(err?.response ? err.response : err.message);
      localStorage.removeItem('auth');
      alert(
        'We have not been able to obtain your credentials, please log in again',
      );
      return (window.location.href = '/signOut');
    });
};

instance.interceptors.request.use(
  (req: any) => {
    if (!!req.headers['Authorization']) return req;
    req.headers = headers();
    return req;
  },
  (error) => {
    return Promise.reject(error);
  },
);

createAuthRefreshInterceptor(instance, refreshToken, {
  statusCodes: [401, 403, 400],
});

export default instance;
