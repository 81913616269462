import { Typography } from 'antd';
import { useState } from 'react';
import CurrentSettingsView from './components';
import { settingsList } from 'helpers/listDashboard';
import ListDash from 'components/listDash/listDash';

const { Title } = Typography;

const SettingsPage = () => {
  const [state, setState] = useState({
    list: settingsList,
    active: 'profile',
  });

  const onClick = (id: any) => {
    setState((prev) => {
      const list = prev.list.map((i) =>
        i.id === id ? { ...i, active: true } : { ...i, active: false },
      );
      return { ...prev, list, active: id };
    });
  };

  return (
    <div
      className="animate__animated animate__fadeIn"
      style={{ minHeight: '75vh', position: 'relative' }}
    >
      <Title level={1} style={{ marginBlockEnd: 0 }}>
        Settings
      </Title>
      {/* <div className="view-desktop">
        <ActionsSettings />
      </div> */}
      <ListDash state={state.list} onClick={onClick} />

      {/*Current view*/}
      <CurrentSettingsView active={state.active} />

      {/*View mobile*/}
      {/* <div className="view-mobile">
        <ActionsSettings />
      </div> */}
    </div>
  );
};

export default SettingsPage;
