import { Spin } from 'antd';
import { useEffect } from 'react';
import { useGoogleApi } from 'react-gapi';
import { scopes } from 'config/google/scopes';
import logout from 'config/google/logout';
import { useDispatch } from 'react-redux';

const AuthGoogle = () => {
  // @ts-ignore
  const gapi = useGoogleApi({ scopes });
  const auth = gapi?.auth2?.getAuthInstance();
  const dispatch = useDispatch();

  useEffect(() => {
    logout(dispatch, auth);
  }, []);

  return (
    <div
      style={{
        position: 'fixed',
        height: '100%',
        width: '100%',
        top: '0',
        left: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spin size="large" />
    </div>
  );
};

export default AuthGoogle;
