import { Button, Typography } from 'antd';

const UpgradeSub = () => {
  return (
    <div className="card__create-form card__list-wrapper mb-5">
      <div className="mt-3" style={{ width: '100%' }}>
        <div className="mb-4">
          <Typography.Title level={2}>Upgrade Plan</Typography.Title>
          <Typography.Text>
            <b>Note:</b> You need to pay licenses for all collaborators from
            your Google Workspace(G Suite) Account
          </Typography.Text>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type="primary"
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_AUTH_ACCOUNT_HOST}/checkout`;
            }}
          >
            Pay Now
          </Button>
        </div>
      </div>
    </div>
  );
};
export default UpgradeSub;
