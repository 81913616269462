import { useMemo } from 'react';
import moment, { MomentInput } from 'moment';
import UpgradeSub from './upgradeSub';
import { format } from 'helpers/momentConfig';
import useStateRedux from 'hooks/useStateRedux';
import { Descriptions } from 'antd';

const Subscription = () => {
  const { licenses, edition, ...rest }: any = useStateRedux('auth');

  const avaliableLicences = useMemo(() => licenses - rest.mailBox, [rest]);
  const licencesStyle = useMemo((): React.CSSProperties => {
    if (avaliableLicences < 0)
      return {
        color: '#FFFFFF',
        backgroundColor: '#FF0000',
      };
    else if (avaliableLicences > 0)
      return {
        color: '#FFFFFF',
        backgroundColor: '#60B760',
      };
    return {};
  }, [avaliableLicences]);

  const expirationDate = useMemo((): MomentInput => {
    if (edition == 'EXPIRED') return rest.purchase_date;
    if (edition == 'TRIAL') return moment(rest.created_at).add(14);
    if (rest.purchase_date) {
      const match = edition.match(/\d+/);
      const monthsToAdd = parseInt(match[0], 10);
      return moment(rest.purchase_date)
        .add(monthsToAdd, 'months')
        .format('YYYY-MM-DD');
    }
    return null;
  }, [rest]);

  const nextChargeDate = useMemo((): MomentInput => {
    if (['EXPIRED', 'TRIAL'].includes(edition)) return null;
    if (rest.purchase_date) {
      const match = edition.match(/\d+/);
      const monthsToAdd = parseInt(match[0], 10);
      return moment(rest.purchase_date)
        .add(monthsToAdd, 'months')
        .format('YYYY-MM-DD');
    }
    return null;
  }, [rest]);

  return (
    <div>
      <div className="card__list-wrapper card__create-form">
        <div style={{ width: '100%' }}>
          <Descriptions title="Subscription Information" column={2}>
            <Descriptions.Item
              labelStyle={{ fontWeight: 600 }}
              label="Current Plan"
            >
              {edition}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: 600 }}
              label="Payment Method"
            >
              {rest.payment_method}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: 600 }}
              label="Expiration Date"
            >
              {moment(expirationDate).format(format)}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: 600 }}
              label="Next Charge"
            >
              {nextChargeDate ? moment(nextChargeDate).format(format) : 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: 600 }}
              label="Licenses"
            >
              {licenses}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: 600 }}
              label="Mail Boxes"
            >
              {rest.mailBox}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: 600 }}
              label="Licences Status"
              contentStyle={{
                ...licencesStyle,
                justifyContent: 'center',
                borderRadius: '5px',
              }}
            >
              {Math.abs(avaliableLicences)}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </div>
      {['TRIAL', 'EXPIRED'].includes(edition) || avaliableLicences < 1 ? (
        <UpgradeSub />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Subscription;
