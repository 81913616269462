import axios from 'config/axios/v1Passport.instance';
import googleIntance from 'config/axios/google.intance';
import { UserInfo } from '../interface/loginApi';

export const loginAuth = async (body: any) => {
  return await axios
    .post('/auth/login', body)
    .then(({ data }: any) => {
      return { err: false, data };
    })
    .catch((err) => ({
      err: err?.response?.data || { code: 'FAIL', name: err?.message },
      data: null,
    }));
};

export const getUserInfo = (access_token: string) => {
  return googleIntance
    .get<UserInfo>(`/userinfo`, {
      params: {
        access_token: access_token,
      },
    })
    .then(({ data }) => {
      return { data, err: null };
    })
    .catch((error) => {
      return { data: null, err: error?.response?.data || error };
    });
};
