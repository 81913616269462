import { notification, Pagination, Typography } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import NotData from 'components/result/notData';
import Loading from 'components/loading/loading';
import { syncPeople, useFetchPeopleList } from 'api/person/person.api';
import CardListPeople from 'components/card/cardListPeople';
import { ActionsNew } from 'components/actionsForm/actionsForm';
import useStateRedux from 'hooks/useStateRedux';

const { Title } = Typography;

const PeopleListPage = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const { data, loading } = useFetchPeopleList(page);
  const { customer_id, mailbox }: any = useStateRedux('auth');

  const syncPeopleFn = async () => {
    try {
      const mailBox = mailbox || 0;
      const stimatedSeconds = (() => {
        if (mailBox <= 50) return 60;
        if (mailBox <= 500) return 120;
        if (mailBox <= 1000) return 180;
        if (mailBox <= 5000) return 240;
        if (mailBox <= 10000) return 300;
        else return 360;
      })();

      const stimatedMinutes = stimatedSeconds / 60;
      const estimatedTime =
        Math.round(stimatedMinutes * 100) / 100 + ' Minutes';
      notification.info({
        message: 'User synchronization',
        description: (
          <span>
            User synchronization has started
            <br />
            reload again in about <b> {estimatedTime} </b>
          </span>
        ),
      });
      await syncPeople(customer_id);
    } catch (error) {}
  };
  const onClick = (payload: any) => history.push(`/profile/${payload.id}`);
  const handlePagination = (pagination: any) => setPage(pagination);

  const pagination = {
    showSizeChanger: false,
    pageSize: 25,
    total: data.total_items,
    current: page,
  };

  return (
    <div
      className="animate__animated animate__fadeIn"
      style={{ minHeight: '75vh', position: 'relative' }}
    >
      <Title level={1} style={{ marginBlockEnd: 0 }}>
        People List
      </Title>
      <ActionsNew text="Sync People" onClick={syncPeopleFn} />

      <div className="mt-3">
        {loading ? (
          <Loading />
        ) : !data.items.length ? (
          <NotData />
        ) : (
          <>
            {data.items.map((u: any) => (
              <CardListPeople {...u} key={u.id} onClick={onClick} />
            ))}
            <div className="text-right mt-2">
              <Pagination
                {...pagination}
                onChange={handlePagination}
                responsive={true}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PeopleListPage;
