import React from 'react';
import moment from 'moment';
import { Button, Space, Tooltip } from 'antd';
import { format } from 'helpers/momentConfig';
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DiffOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';

const columnsSignature = (onDelete: any, onOpenExecutions: any) => [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: (a: any, b: any) => a.name.length - b.name.length,
  },
  {
    title: 'Set Automatically',
    dataIndex: 'active',
    render: (text: any, record: any) => (
      <div className="text-center">
        {!!record.active ? (
          <CheckOutlined style={{ color: '#28a745' }} />
        ) : (
          <CloseOutlined className="text-danger" />
        )}
      </div>
    ),
  },
  {
    title: 'Frequency',
    dataIndex: ['policy', 'execution_type'],
    render: (text: any, record: any) => (
      <div className="text-center">
        {record?.policy?.execution_type || '---'}
      </div>
    ),
  },
  {
    title: 'Created at',
    dataIndex: 'created_date',
    render: (text: any, record: any) => (
      <Space>{moment(record.created_date).format(format)}</Space>
    ),
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_date',
    render: (text: any, record: any) => (
      <Space>{moment(record.update_date).format(format)}</Space>
    ),
  },
  {
    title: 'Action',
    render: (text: any, record: any) => (
      <Space size="middle">
        <Button
          type="text"
          onClick={(event) => onDelete(event, record)}
          icon={
            <Tooltip title={`Delete ${record.name}`}>
              <DeleteOutlined className="text-danger" />
            </Tooltip>
          }
        />
        <Button
          type="text"
          onClick={(e) => onOpenExecutions(e, record)}
          icon={
            <Tooltip title={`Executions`}>
              <DiffOutlined className="text-danger" />
            </Tooltip>
          }
        />
      </Space>
    ),
  },
];

const columnsTemplate = (onDelete: any) => [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: (a: any, b: any) => a.name.length - b.name.length,
  },
  {
    title: 'Summary',
    dataIndex: 'summary',
  },
  {
    title: 'Created By',
    dataIndex: 'created_by',
  },
  {
    title: 'Created at',
    dataIndex: 'created_date',
    render: (text: any, record: any) => (
      <span>{moment(record.created_date).format(format)}</span>
    ),
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_date',
    render: (text: any, record: any) => (
      <span>{moment(record.update_date).format(format)}</span>
    ),
  },
  {
    title: 'Action',
    render: (text: any, record: any) => (
      <Space size="middle">
        <Button
          type="text"
          onClick={(event) => onDelete(event, record)}
          icon={
            <Tooltip title={`Delete ${record.name}`}>
              <DeleteOutlined className="text-danger" />
            </Tooltip>
          }
        />
      </Space>
    ),
  },
];

const columnsPeople = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: (a: any, b: any) => a.name.length - b.name.length,
  },
  {
    title: 'Email',
    dataIndex: 'primary_email',
  },
  {
    title: 'Organization Unit',
    dataIndex: 'org_unit_path',
  },
  {
    title: 'Department',
    dataIndex: 'department',
  },
  {
    title: 'Signature',
    dataIndex: 'signature',
    render: (text: any, record: any) => (
      <span>
        {record.signature ? (
          <CheckCircleOutlined className="text-success" />
        ) : (
          <CloseCircleOutlined className="text-danger" />
        )}
      </span>
    ),
  },
  {
    title: 'enable',
    dataIndex: 'suspended',
    render: (text: any, record: any) => (
      <span>
        {record.suspended ? (
          <CheckCircleOutlined className="text-success" />
        ) : (
          <CloseCircleOutlined className="text-danger" />
        )}
      </span>
    ),
  },
];

const columnLabels = (handleModal: (visible: boolean) => void) => {
  const onAddContact = (event: any) => {
    event.stopPropagation();
    handleModal(true);
  };
  return [
    {
      title: 'Name',
      dataIndex: 'displayName',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text: any) => (
        <span>
          <UserOutlined />
          <span style={{ marginLeft: 5 }}>{text}</span>
        </span>
      ),
    },
    {
      title: 'Contacts',
      dataIndex: 'contacts',
      render: () => (
        <Button type="default" onClick={onAddContact}>
          <UserAddOutlined />
        </Button>
      ),
    },
  ];
};

export { columnsSignature, columnsTemplate, columnsPeople, columnLabels };
