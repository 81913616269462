import { useMedia } from 'hooks/useMedia';
import DashRoutes from './dashboard/dashRoutes';
import PrivateRoute from './utils/router.private';
import { useEffect, useState } from 'react';
import { setAuth } from 'redux/actions/auth.action';
import { useDispatch, useSelector } from 'react-redux';
import { handleIsMobile } from 'redux/actions/ui.action';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AuthGoogle from 'pages/auth/google/authGoogle';
import SignOut from 'pages/auth/signOut';

const AppRoutes = () => {
  const dispatch = useDispatch();
  const query = useMedia('(max-width: 768px)');
  const { isMobile } = useSelector((state: any) => state.ui);
  const [compareScreen] = useState(isMobile !== query);

  useEffect(() => {
    const auth = localStorage.getItem('auth');
    if (!!auth) dispatch(setAuth({ ...JSON.parse(auth), loading: false }));
    else dispatch(setAuth({ loading: false }));
  }, []);

  useEffect(() => {
    if (compareScreen) dispatch(handleIsMobile(query));
  }, [compareScreen, dispatch, query]);

  return (
    <BrowserRouter>
      <Switch>
        {/*public Routes*/}
        {/* <Route path="/login" component={AuthRoutes} /> */}
        <Route path="/auth/callback/google" component={AuthGoogle} />
        <Route path="/signOut" component={SignOut} />
        {/* <Route path="/success-installation" component={SuccessInstallation} /> */}
        {/* <Route path="/not-access" component={NotAccess} /> */}
        {/* <Route path="/not-admin" component={NotAdmin} /> */}
        {/* <Route path="/not-installation" component={NotInstallation} /> */}

        {/*Not access, complete register*/}
        {/* <PrivateGoogleRoute path="/pricing" exact component={PricingPage} />

        <PrivateGoogleRoute
          isAdmin={false}
          path="/account/pricing"
          component={SelectDomainPage}
        />
        <PrivateGoogleRoute path="/account" component={CompleteUserRoutes} /> */}

        {/*complete access*/}
        <PrivateRoute path="/" component={DashRoutes} />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRoutes;
