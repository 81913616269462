import apiV1 from 'config/axios/v1.instance';
import { initSignatureList } from '../initialStates';
import { useCallback, useEffect, useState } from 'react';
import { ISignatureListModel } from 'models/api/signature.model';
import axiosPassport from 'config/axios/v1Passport.instance';

export const useFetchPeopleList = (page = 1) => {
  // definition state
  const [data, setData] = useState<ISignatureListModel>(initSignatureList);
  const [state, setState] = useState({ loading: true, error: null });

  // get data server api
  const getDataServer = useCallback(() => {
    apiV1
      .get<ISignatureListModel>(`/people?page=${page}`)
      .then(({ data }) => {
        setData(data);
        setState((prev) => ({ ...prev, loading: false }));
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          loading: false,
          error: !!err?.response ? err?.response?.data : err?.message,
        }));
      });
  }, [page]);

  useEffect(() => {
    getDataServer();
  }, [page, getDataServer]);

  // return state
  return { data, ...state, refresh: getDataServer };
};

export const getByIdProfile = (id: string | number | undefined) => {
  // get data server api
  return apiV1
    .get(`/people/${id}`)
    .then(({ data }) => {
      return { data, err: null };
    })
    .catch((error) => {
      console.log(error?.response?.data || error);
      return { data: null, err: error?.response?.data || error };
    });
};

export const updateProfileById = (id: string | number, body: any) => {
  // get data server api
  return apiV1
    .put(`/people/${id}`, body)
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error?.response?.data || error);
      return false;
    });
};

export const syncPeople = (id: string, modules = ['users']) => {
  return axiosPassport
    .get(`/customers/sync/${id}`, {
      params: Object.fromEntries(modules.map((x) => [x, true])),
    })
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error?.response?.data || error);
      return false;
    });
};
