import { Spin } from 'antd';
import useQuery from 'hooks/useQuery';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Aside from 'components/aside/aside';
import { getStorage } from 'helpers/storage';
import Navbar from 'components/navbar/navbar';
import useStateRedux from 'hooks/useStateRedux';
import { setAuth } from 'redux/actions/auth.action';
import AlertUpgrade from 'components/alert/alertUpgrade';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getRefreshToken } from 'config/utils/refreshToken';
import {
  CreateOrEditOutOfficePage,
  CreateSignaturePage,
  CreateTemplatePage,
  DashboardPage,
  OutOfficeListPage,
  PeopleListPage,
  ProfilePage,
  SettingPage,
  SharedContact,
  SignatureListPage,
  SubscriptionPage,
  TemplateListPage,
} from 'pages';
import ExecutionListPage from 'pages/execution/executionListPage';
import ExecutionDetailListPage from 'pages/execution/executionDetailListPage';

const DashRoutes = ({ location }: any) => {
  const dispatch = useDispatch();
  const refresh = useQuery().get('refresh');
  const { edition }: any = useStateRedux('auth');

  // Refreshing token with param
  useEffect(() => {
    if (!!refresh) {
      getRefreshToken().then(() => {
        const auth = getStorage('auth');
        dispatch(setAuth(auth));
      });
    }
  }, []);

  return (
    <div className="dashboard___wrapper-main-content">
      {edition === 'TRIAL' && <AlertUpgrade />}
      {/*<AlertCompleted />*/}
      <div className="dashboard__main-content">
        <Aside />
        <div className="dashboard__body">
          <Navbar />
          <div
            className={
              location.pathname === '/sharedContactsList'
                ? ''
                : 'dashboard__content beauty-scroll'
            }
          >
            <div>
              <Switch>
                <Route exact path="/" component={DashboardPage} />
                <Route
                  exact
                  path="/signatureCreate"
                  component={CreateSignaturePage}
                />
                <Route
                  exact
                  path="/signature/:signatureId/executions"
                  component={ExecutionListPage}
                />
                <Route
                  exact
                  path="/signature/:signatureId/executions/:executionId"
                  component={ExecutionDetailListPage}
                />
                <Route
                  exact
                  path="/signatureList"
                  component={SignatureListPage}
                />
                <Route
                  exact
                  path="/signatureTemplateList"
                  component={TemplateListPage}
                />
                <Route
                  exact
                  path="/signatureTemplateCreate"
                  component={CreateTemplatePage}
                />
                <Route exact path="/entity/person" component={PeopleListPage} />
                <Route exact path="/profile/:id" component={ProfilePage} />
                <Route
                  exact
                  path="/sharedContactsList"
                  component={SharedContact}
                />
                <Route exact path="/setting" component={SettingPage} />
                <Route
                  exact
                  path="/accountProfile"
                  component={SubscriptionPage}
                />
                <Route exact path="/out-office" component={OutOfficeListPage} />
                <Route
                  exact
                  path="/out-office/create"
                  component={CreateOrEditOutOfficePage}
                />
                <Redirect to="/" />
              </Switch>
              <div style={{ height: 100 }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const SaveSpinner = () => (
  <div className="dashboard__loading-save animate__animated animate__fadeInDown animate__faster">
    <Spin /> <span className="ml-1">Saving</span>
  </div>
);

export default DashRoutes;
