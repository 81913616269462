import { resetAuth } from 'redux/actions/auth.action';

export default async function logout(dispatch: any, auth?: any) {
  try {
    if (auth) {
      await auth.signOut();
    }
  } catch (e) {}

  const hostZumpul = process.env.REACT_APP_AUTH_ACCOUNT_HOST;
  const returnUrl = encodeURIComponent(location.origin);
  const redirectUrl = new URLSearchParams({
    return_url: `${returnUrl}/auth/callback/google`,
  });

  dispatch(resetAuth());
  localStorage.clear();

  window.location.href = `${hostZumpul}/signOut?${redirectUrl.toString()}`;
}
