import React from 'react';
import TextLoop from 'react-text-loop';
import { Alert, Button } from 'antd';

const AlertUpgrade = () => {
  return (
    <Alert
      banner
      style={{
        fontSize: '16px',
        background: '#7b68ee',
      }}
      message={
        <TextLoop mask interval={8000}>
          <div style={{ color: '#ffffff' }}>
            You have a free period for 15 days, you only have DAYS left, upgrade
            to a higher plan to complete Zumpul
          </div>
          <div style={{ color: '#ffffff' }}>
            The FREE plan has limited licenses available
          </div>
        </TextLoop>
      }
      action={
        <Button
          onClick={() => {
            window.location.href = `${process.env.REACT_APP_AUTH_ACCOUNT_HOST}/checkout`;
          }}
          type="primary"
        >
          Upgrade
        </Button>
      }
    />
  );
};

export default AlertUpgrade;
